import { useState, CSSProperties } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import "./LoadingPage.css";
import Logo from "../../../logo.svg";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

export default function LoadingPage(props) {
    let [loading, setLoading] = useState(props.isLoading);
    let [color, setColor] = useState("#60be44");

  return (
    <>
    <div className="sweet-loading">
    <img src={Logo} style={{width:"50%", position:'absolute',top:'40%'}}/>
    <PuffLoader
      color={color}
      loading={loading}
      cssOverride={override}
     size={200}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  </div>
  </>
  )
}
