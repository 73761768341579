
import React, { useEffect, useState } from 'react';
import './AddMember.css';
import { useForm } from 'react-hook-form';
import showMessage from '../../../libraries/messages/messages'
import memberMessage from '../../../main/messages/memberMessage'
import memberValidation from '../../../main/validations/memberValidation'
import MemberTestService from '../../../main/mocks/MemberTestService';
import memberHTTPService from '../../../main/services/memberHTTPService';
import staffHTTPService from '../../../main/services/staffHTTPService';
import activityHTTPService from '../../../main/services/activityHTTPService';
import typeSubsHTTPService from '../../../main/services/typeSubsHTTPService';
import groupHTTPService from '../../../main/services/groupHTTPService'
import { HTTP_ERR_MESSAGE } from '../../../main/messages/generic.message';
import { v4 as uuidv4 } from 'uuid';

const AddMember = (props) => {


  const initialState = {
    member_name: "",
    parent_name: "",
    birth_date: "",
    group: "1",
    activity: "",
    address: "",
    mobile: "",
    email: "",
    school: "",
    start_date: "",
    end_date: "",
    type: "",
    coach: "",
    user_code:"",
    card_no:""
  };

  const { register, handleSubmit, errors } = useForm()
  const [member, setMember] = useState(initialState);
  const [staffs, setStaffs] = useState([]);
  const [activities, setActivities] = useState([]);
  const [groups, setGroups] = useState([]);
  const [typeSubs, setTypeSubs] = useState([]);
  const [age, setAge] = useState(0);


  useEffect(() => {
    getAllStaffs()
    getAllActivities()
    getAllGroups()
    getTypeSubs()
  }, []);

  const onSubmit = (data) => {
    const generatedCode = generateUniqueCode(); // Step 3: Generate a unique code
   data.user_code = generatedCode; // Assign the generated code to the user_code field

    memberHTTPService.createMember(data).then(data => {
      props.closeModal()
      setMember(initialState)
      showMessage('Confirmation', memberMessage.add, 'success')
    }).catch(err => {
      console.log(err.message)
      showMessage('Confirmation', err.message, 'warning')
    })

  }

  const generateUniqueCode = () => {
    const code = uuidv4();
    return code;
  }

  const getAllStaffs = () => {
    staffHTTPService.getAllStaff()
      .then(response => {
        setStaffs(response.data);
      })
      .catch(e => {
        showMessage('Error', HTTP_ERR_MESSAGE, 'warning')
      });
  };

  const getAllActivities = () => {
    activityHTTPService.getAllActivity()
      .then(response => {
        setActivities(response.data);
      })
      .catch(e => {
        showMessage('Error', HTTP_ERR_MESSAGE, 'warning')
      });
  };

  const getTypeSubs = () => {
    typeSubsHTTPService.getAllTypeSubs()
      .then(response => {
        setTypeSubs(response.data);
      })
      .catch(e => {
        showMessage('Error', HTTP_ERR_MESSAGE, 'warning')
      });
  };

  const getAllGroups = () => {
    groupHTTPService.getAllGroups()
      .then(response => {
        setGroups(response.data);
      })
      .catch(e => {
        showMessage('Error', HTTP_ERR_MESSAGE, 'warning')
      });
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setMember({ ...member, [name]: value });
  };



  useEffect(()=>{
    if(member.birth_date!==''){
      const today = new Date();
      const selectedDate = new Date(member.birth_date);
      let calculatedAge = today.getFullYear() - selectedDate.getFullYear();
      const monthDiff = today.getMonth() - selectedDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < selectedDate.getDate())) {
        calculatedAge--;
      }
      setAge(calculatedAge);
    }
   
  },[member.birth_date])

  useEffect(()=>{
    if(age>16){
      setMember({ ...member, ['group']: '12' });
    }else if(age>14){
      setMember({ ...member, ['group']: '11'});
    }else if(age>12){
      setMember({ ...member, ['group']: '9'});
    }else if(age>10){
      setMember({ ...member, ['group']: '8'});
    }else{
      setMember({ ...member, ['group']: '1'});
    }
  },[age])


  return (
    <div className="AddMember">

      <form onSubmit={handleSubmit(onSubmit)}>
        <div class="form-group row">
          <label for="text1" class="col-4 col-form-label">Member Name</label>
          <div class="col-8">
            <input onChange={handleInputChange} value={member.member_name} ref={register({ required: false })}
              id="text1" name="member_name" type="text" class="form-control" />
            <div className="error text-danger">
              {errors.member_name && memberValidation.member_name}
            </div>
          </div>
        </div>


        <div class="form-group row">
          <label for="text2" class="col-4 col-form-label">Parent Name</label>
          <div class="col-8">
            <input onChange={handleInputChange} value={member.parent_name} ref={register({ required: false })}
              id="text2" name="parent_name" type="text" class="form-control" />
            <div className="error text-danger">
              {errors.parent_name && memberValidation.parent_name}
            </div>
          </div>
        </div>


        <div class="form-group row">
          <label for="text" class="col-4 col-form-label">Date of birth</label>
          <div class="col-8">
            <input onChange={handleInputChange} value={member.birth_date} ref={register({ required: false })}
              id="text" name="birth_date" type="date" class="form-control" />
            <div className="error text-danger">
              {errors.birth_date && memberValidation.birth_date}
            </div>
          </div>
        </div>


        {/* <div class="form-group row">
          <label for="select1" class="col-4 col-form-label">Age Group</label>
          <div class="col-8">
            <select onChange={handleInputChange} value={member.group} ref={register({ required: false })}
              id="select1" name="group" class="custom-select">
              {groups.map(item =>
                <option value={item.id}>{item.name}</option>
              )}
            </select>
            <div className="error text-danger">
              {errors.group && memberValidation.group}
            </div>
          </div>
        </div> */}

             <input value={member.group} 
                     id="select1" name="group" type="hidden" class="custom-select" ref={register({ required: false })} />

        <div class="form-group row">
          <label for="select2" class="col-4 col-form-label">Activity (Sport)</label>
          <div class="col-8">
            <select onChange={handleInputChange} value={member.activity} ref={register({ required: false })}
              id="select2" name="activity" class="custom-select">
              {activities.map(item =>
                <option value={item.id}>{item.title}</option>
              )}
            </select>
            <div className="error text-danger">
              {errors.type && memberValidation.type}
            </div>
          </div>
        </div>



        <div class="form-group row">
          <label for="text3" class="col-4 col-form-label">Address</label>
          <div class="col-8">
            <input onChange={handleInputChange} value={member.address} ref={register({ required: false })}
              id="text3" name="address" type="text" class="form-control" />
            <div className="error text-danger">
              {errors.address && memberValidation.address}
            </div>
          </div>
        </div>


        <div class="form-group row">
          <label for="text4" class="col-4 col-form-label">Mobile</label>
          <div class="col-8">
            <input onChange={handleInputChange} value={member.mobile} ref={register({ required: false })}
              id="text4" name="mobile" type="number" class="form-control" />
            <div className="error text-danger">
              {errors.mobile && memberValidation.mobile}
            </div>
          </div>
        </div>


        <div class="form-group row">
          <label for="text5" class="col-4 col-form-label">Email</label>
          <div class="col-8">
            <input onChange={handleInputChange} value={member.email} ref={register({ required: false })}
              id="text5" name="email" type="email" class="form-control" />
            <div className="error text-danger">
              {errors.email && memberValidation.email}
            </div>
          </div>
        </div>


        {/* <div class="form-group row">
          <label for="text6" class="col-4 col-form-label">Email</label>
          <div class="col-8">

            <div class="input-group mb-3">
              <input onChange={handleInputChange} value={member.email} ref={register({ required: false })}
                id="text6" name="email" type="email" class="form-control" />
            </div>
            <div className="error text-danger">
              {errors.email && memberValidation.email}
            </div>
          </div>
        </div> */}


        <div class="form-group row">
          <label for="text7" class="col-4 col-form-label">School</label>
          <div class="col-8">
            <div class="input-group mb-3">
              <input onChange={handleInputChange} value={member.school} ref={register({ required: false })}
                id="text7" name="school" type="text" class="form-control" />
            </div>
            <div className="error text-danger">
              {errors.school && memberValidation.school}
            </div>
          </div>
        </div>



        <div class="form-group row">
          <label for="text12" class="col-4 col-form-label">Start Date</label>
          <div class="col-8">
            <input onChange={handleInputChange} value={member.start_date} ref={register({ required: false })}
              id="text12" name="start_date" type="date" class="form-control" />
            <div className="error text-danger">
              {errors.start_date && memberValidation.start_date}
            </div>
          </div>
        </div>


        <div class="form-group row">
          <label for="text13" class="col-4 col-form-label">End Date</label>
          <div class="col-8">
            <input onChange={handleInputChange} value={member.end_date} ref={register({ required: false })}
              id="text13" name="end_date" type="date" class="form-control" />
            <div className="error text-danger">
              {errors.end_date && memberValidation.end_date}
            </div>
          </div>
        </div>


        <div class="form-group row">
          <label for="select" class="col-4 col-form-label">Subscription type</label>
          <div class="col-8">
            <select onChange={handleInputChange} value={member.type} ref={register({ required: false })}
              id="select" name="type" class="custom-select">
              {typeSubs.map(item =>
                <option value={item.id}>{item.category}</option>
              )}
            </select>
            <div className="error text-danger">
              {errors.type && memberValidation.type}
            </div>
          </div>
        </div>


        <div class="form-group row">
          <label for="select3" class="col-4 col-form-label">Coach</label>
          <div class="col-8">
            <select onChange={handleInputChange} value={member.coach} ref={register({ required: false })}
              id="select3" name="coach" class="custom-select">

              {staffs.map(item =>
                <option value={item.id}>{item.first_name}</option>
              )}
            </select>
            <div className="error text-danger">
              {errors.coach && memberValidation.coach}
            </div>
          </div>
        </div>


        <div class="form-group row">
          <div class="offset-4 col-8">
            <button name="submit" type="submit" class="btn btn-primary"><i class="far fa-save"></i>
              Save</button>
          </div>
        </div>


      </form>
    </div>
  )
};

AddMember.propTypes = {};

AddMember.defaultProps = {};

export default AddMember;
