 import logo from '../../../logo.svg';
import Scan from './NfcCardReader/NfcCardReader';
import Write from './NfcCardWriter/NfcCardWriter';
import { useEffect, useState } from 'react';
import { ActionsContext } from '../../../libraries/contexts/context';
import './NfcCardReader/Scanner.css'

function NfcCardRW() {

  const [actions, setActions] = useState(null);
  const {scan, write} = actions || {};


  const actionsValue = {actions,setActions};

  const onHandleAction = (actions) =>{
    setActions({...actions});
  }

  return (
    <div className="scanner">
        <img src={logo} style={{width:'20%'}} alt="finwin" />
        <div className="App-container">
        <button onClick={()=>onHandleAction({scan: 'scanning', write: null})} className="btn">Scan</button> 
          {/* <button onClick={()=>onHandleAction({scan: null, write: 'writing'})} className="btn">Write</button> */}
        </div>
        <ActionsContext.Provider value={actionsValue}>
          {scan && <Scan/>}
          {/* {write && <Write/>} */}
        </ActionsContext.Provider>
      </div>
  );
}

export default NfcCardRW;
