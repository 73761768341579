import React, { useCallback, useContext, useEffect, useState } from 'react';
import Writer from './Writer';
import showMessage from '../../../../libraries/messages/messages';
import { ActionsContext } from '../../../../libraries/contexts/context';
import memberHTTPService from '../../../../main/services/memberHTTPService';
import Chip from '@mui/material/Chip';
import Save from './SVGs/save';

export default function NfcCardWriter({userData}) {
    const [nfcCode, setNfcCode]= useState('')

    const { actions, setActions} = useContext(ActionsContext);


    const onHandleAction = (actions) =>{
        setActions({...actions});
      }

      const scan = useCallback(async() => {

        if ('NDEFReader' in window) { 
            try {
                const ndef = new window.NDEFReader();
                await ndef.scan();
                
                console.log("Scan started successfully.");
                ndef.onreadingerror = () => {
                    console.log("Cannot read data from the this card. Try another one?");
                    setNfcCode('error')
                };
                
                ndef.onreading = event => {
                    console.log("NDEF card read.");
                    setNfcCode(event);
                    setActions({
                        scan: 'scanned',
                        write: 'writing'
                    });
                };
      
            } catch(error){
                console.log(`Error! Scan failed to start: ${error}.`);
            };
        }
      },[setActions]);

    
    const onWrite = async() => {
        try {
            const ndef = new window.NDEFReader();
            await ndef.scan();
            const encoder = new TextEncoder();
            regiterMemberCard({card_no:nfcCode.serialNumber})
            await ndef.write({records: [{ recordType: "mime",mediaType: "application/json", data: encoder.encode(JSON.stringify(userData))}]});
            alert(`Registration Success!`);
            onHandleAction({scan: null, write: null})
        } catch (error) {
            console.log(error);
        }


    }

    const regiterMemberCard = (data) => {
      memberHTTPService.registerCard(userData.id, data)
        .then(response => {
          // Check the response status or any other condition to determine success
          if (response.status === 200) {
            console.log("Card registration successful.");
            showMessage('Card Regitered', 'Registration Success', 'success');
          } else {
            console.log("Card registration failed.");
            // Handle the failure case if needed
          }
        })
        .catch(error => {
          console.log("Error occurred during card registration:", error);
          // Handle any errors that occurred during the registration process
        });
    };


   useEffect(() => {
        scan();
      }, [scan]);

  return (
    <>
      { nfcCode.serialNumber &&  
      <div style={{paddingTop:'40px'}}>
      <Chip label={'NFC Card Detected :'+ nfcCode.serialNumber} color="success" variant="outlined" />
       <button className="btn" onClick={()=>onWrite()}>
        <Save/> Register
        </button>
      </div>
       }
  </>
  )
}
