import logo from './logo.svg';
import { useState,useEffect } from 'react';
import './App.css';
import Root from './main/shared/Root/Root';
import { ActionsContext } from './libraries/contexts/context';
import LoadingPage from './main/shared/Loading/LoadingPage';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Logo from "./logo.svg";

function App() {
  const [actions, setActions] = useState(null);
  const {scan, write} = actions || {};
  const actionsValue = {actions,setActions};

  const [isLoading,setLoading]= useState(true);


  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 1000);
  return () => clearTimeout(timer);
}, []);


  return (
    <ActionsContext.Provider value={actionsValue}>
   {isLoading ?
   <LoadingPage isLoading={true}/>:
   <Root/>
      }
    </ActionsContext.Provider>
  );
}

export default App;
