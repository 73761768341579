import React, { useEffect, useRef, useState } from 'react';
import './ManualAttendance.css';
import { LoadJS } from '../../../libraries/datatables/datatables';
import EditMember from '../EditMember/EditMember';
import AddMember from '../AddMember/AddMember';
import useForceUpdate from 'use-force-update';
import showMessage from '../../../libraries/messages/messages';
import memberMessage from '../../../main/messages/memberMessage';
import memberHTTPService from '../../../main/services/memberHTTPService';
import ViewMember from '../ViewMember/ViewMember';
import { Button, LinearProgress, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import MemberSummary from '../MemberSummary/MemberSummary';
import MemberBarChart from '../MemberBarChart/MemberBarChart';
import { HTTP_ERR_MESSAGE } from '../../../main/messages/generic.message';
import User from '../../../main/config/user';
import { ActionsContext } from '../../../libraries/contexts/context';
import NfcCardWriter from '../../shared/NfcCardRW/NfcCardWriter/NfcCardWriter';
import attendanceHTTPService from '../../../main/services/attendanceHTTPService';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Select from 'react-select';
import {Howl, Howler} from 'howler';
import error from '../../../main/music/error.mp3';
import success from '../../../main/music/success.mp3';


const ManualAttendance = ({setManualAttendanceClick}) => {

  const [members, setMembers] = useState([]);
  const [updatedItem, setUpdatedItem] = useState({});
  const forceUpdate = useForceUpdate();
  const [message, setMessage] = useState('');
  const [memberName, setMemberName] = useState('');
  const [selected, setSelected] = React.useState('');
  const [actions, setActions] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [date, setDate] = useState(new Date());

  const iosDate = new Date(date).toISOString();


  const onHandleAction = (actions) =>{
    setActions({...actions});
  }

  var successSound = new Howl({
    src: [success],
    html5: true,
  });

  var errorSound = new Howl({
    src: [error],
    html5: true,
  });

  useEffect(() => {
    LoadJS()
    getAllMember()
  }, []);

  const handleChange = (event) => {
    if(event!==null){
      setSelected(event.value);
    }
    setErrorMessage('')
  };

  const getAllMember = () => {
    memberHTTPService.getAllMember()
      .then(response => {
        setMembers(response.data);
        forceUpdate()
      })
      .catch(e => {
        showMessage('Error', HTTP_ERR_MESSAGE, 'warning')
      });
  };


  const CheckManualAttendance = async () => {

    const data ={
      memberId:selected.id,
      date :iosDate
    }
    try {
      const response = await attendanceHTTPService.checkAttendanceManual(data);
      setMessage('Attendance Created');
      setMemberName(response.data.title );
      setManualAttendanceClick(false);
      showMessage('Attend success','Attendance mark success')
      successSound.play();
    } catch (error) {
      const errorMes = error.response.data.message ? error.response.data.message : HTTP_ERR_MESSAGE;
      setErrorMessage(errorMes);
      setMemberName(error.response.data.member?error.response.data.member.member_name:'' );
      errorSound.play();
  
    }
  };

  const options = members.map(item => ({
    label: `${item.member_name}`,
    value: item,
  }));


  return (
    <div className="content ">
      <div className="row">
        <div className="col-md-12 ">
          <div  style={{minHeight:'450px'}}>
            <div className="card-header">
              <h4 className="card-title"><i class="nc-icon nc-single-02"></i> Manual Attendance </h4>
            </div>
            <div className="card-body">
              <div className="table" style={{paddingTop:'20px'}}>

              <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select onChange={handleChange}  
                  value={selected.member_name} 
                  options={options}  
                  isClearable={true}/>
   
      </FormControl>
    </Box>

   {selected && <Card sx={{ minWidth: 275 }} style={{background:"#003465",marginTop:'20px'}}>
      <CardContent style={{background:"#0f518e"}}>
        <Typography variant="h5" color="#fff" component="div">
            {selected.member_name&&selected.member_name}
        </Typography>
        <Typography color="#60be44" sx={{ mb: 1.5 }} >
        Activity: {selected.activityDetails.category&&selected.activityDetails.category}
        </Typography>
        <Typography color="#60be44" variant="body2">
          Registered Date: {selected.start_date&&selected.start_date}
          <br />
        </Typography>
      </CardContent>
      <CardActions>
        <button style={{background:'orange'}} onClick={()=>{onHandleAction({scan: null, write: null});setSelected('')}} className="btn">Clear</button>
      </CardActions>
    </Card>}
    { selected.member_name&& 
    <> <Typography color="#60be44" variant="body2" style={{paddingTop:10}}>
          Select Date
        </Typography>
   <input type="date" onChange={(e)=>setDate(e.target.value)} value={date} 
                name="event_date" autocomplete="off"
                class="form-control validate[required] date hasDatepicker" id="dp1609369438332" /></>}

{ selected.member_name&&   <button style={{background:'green', marginTop:20}}  onClick={()=>CheckManualAttendance()} className="btn">Mark Attend</button>}
              </div>
              {errorMessage!=='' &&  <Typography color="red"  sx={{ mb: 1.5 }} >
             {errorMessage}
        </Typography>}
             
            </div>
          </div>
        </div>

      </div>
    </div>
  )
};

ManualAttendance.propTypes = {};

ManualAttendance.defaultProps = {};

export default ManualAttendance;
