
const memberValidation = {
    member_name: 'First name is required',
    parent_name: 'Last name is required',
    birth_date: 'Date of birth is required',
    group: 'Group is required',
    type: 'Type is required',
    address: 'Address is required',
    mobile: 'Telephone is required',
    email: 'Email is required',
    weight: 'Weight is required',
    school: 'School is required',
    chest: 'Poitrine  doit etre definie',
    foot: 'Cuisse doit etre definie',
    hand: 'Bras doit etre definie',
    fat: 'Graisse doit etre definie',
    start_date: 'Start time is required',
    end_date: 'End time is required',
    type: 'Type is required',
    coach: 'Coach is required',



}
export default memberValidation;