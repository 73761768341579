import http from "../../libraries/axios/axios";
import BASE_URL from "../urls/urls";

const getAllGroup = () => {
    return http.get(`${BASE_URL}/api/group`)
}
const createGroup = data => {
    return http.post(`${BASE_URL}/api/group`, data);
};

const editGroup = (id, data) => {
    return http.put(`${BASE_URL}/api/group/${id}`, data);
};

const removeGroup = id => {
    return http.delete(`${BASE_URL}/api/group/${id}`);
};
const getCountGroup = () => {
    return http.get(`${BASE_URL}/api/count/group`)
}

export default {
    getAllGroups: getAllGroup,
    createGroup,
    editGroup,
    removeGroup,
    getCountGroup
};
