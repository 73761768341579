import React, { useEffect, useRef, useState } from 'react';
import './CardRegister.css';
import { LoadJS } from '../../../libraries/datatables/datatables';
import EditMember from '../EditMember/EditMember';
import AddMember from '../AddMember/AddMember';
import useForceUpdate from 'use-force-update';
import showMessage from '../../../libraries/messages/messages';
import memberMessage from '../../../main/messages/memberMessage';
import memberHTTPService from '../../../main/services/memberHTTPService';
import ViewMember from '../ViewMember/ViewMember';
import { Button, LinearProgress, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import MemberSummary from '../MemberSummary/MemberSummary';
import MemberBarChart from '../MemberBarChart/MemberBarChart';
import { HTTP_ERR_MESSAGE } from '../../../main/messages/generic.message';
import User from '../../../main/config/user';
import { ActionsContext } from '../../../libraries/contexts/context';
import NfcCardWriter from '../../shared/NfcCardRW/NfcCardWriter/NfcCardWriter';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Select from 'react-select';

const CardRegister = () => {

  const [members, setMembers] = useState([]);
  const [updatedItem, setUpdatedItem] = useState({});
  const forceUpdate = useForceUpdate();
  const closeButtonEdit = useRef(null);
  const closeButtonAdd = useRef(null);
  const [loading, setLoading] = useState(false);

  const [selected, setSelected] = React.useState('');
  const [actions, setActions] = useState(null);
  const {scan, write} = actions || {};


  const actionsValue = {actions,setActions};

  const onHandleAction = (actions) =>{
    setActions({...actions});
  }


  useEffect(() => {
    LoadJS()
    getAllMember()
  }, []);

  const handleChange = (event) => {
    if(event!==null){
      setSelected(event.value);
    }
  };

  const getAllMember = () => {
    memberHTTPService.getAllMember()
      .then(response => {
        setMembers(response.data);
        forceUpdate()
      })
      .catch(e => {
        showMessage('Error', HTTP_ERR_MESSAGE, 'warning')
      });
  };


  const resfresh = () => {
    getAllMember()
    forceUpdate()
  }

  const updateMemberAction = (e, data) => {
    e.preventDefault();
    setUpdatedItem(data)
    resfresh()
  }

  const options = members.map(item => ({
    label: `${item.member_name}`,
    value: item,
  }));


  return (
    <div className="content ">
      <div className="row">
        <div className="col-md-12 ">
          <div  style={{minHeight:'450px'}}>
            <div className="card-header">
              <h4 className="card-title"><i class="nc-icon nc-single-02"></i> Member Card Register </h4>
            </div>
            <div className="card-body">
              <div className="table" style={{paddingTop:'20px'}}>

              <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select onChange={handleChange}  
                  value={selected.member_name} 
                  options={options}  
                  isClearable={true}/>
   
      </FormControl>
    </Box>

   {selected && <Card sx={{ minWidth: 275 }} style={{background:"#003465",marginTop:'20px'}}>
      <CardContent style={{background:"#0f518e"}}>
        <Typography variant="h5" color="#fff" component="div">
            {selected.member_name&&selected.member_name}
        </Typography>
        <Typography color="#60be44" sx={{ mb: 1.5 }} >
        Activity: {selected.activityDetails.category&&selected.activityDetails.category}
        </Typography>
        <Typography color="#60be44" variant="body2">
          Registered Date: {selected.start_date&&selected.start_date}
          <br />
        </Typography>
      </CardContent>
      <CardActions>
        <button style={{background:'green'}}  onClick={()=>onHandleAction({scan: 'scanning', write: 'writing'})} className="btn">Select</button>
        <button style={{background:'orange'}} onClick={()=>{onHandleAction({scan: null, write: null});setSelected('')}} className="btn">Clear</button>
      </CardActions>
    </Card>}

    <ActionsContext.Provider value={actionsValue}>
         {write && <NfcCardWriter userData={{id:selected.id,userName:selected.member_name, userCode:selected.user_code}}/>} 
        </ActionsContext.Provider>


              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
};

CardRegister.propTypes = {};

CardRegister.defaultProps = {};

export default CardRegister;
