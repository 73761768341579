
const staffValidation = {

    date: 'Date is required',
    role: 'Role is required',
    address: 'Address is required',
    email: 'Email is required',
    mobile: 'Telephone is required',
    first_name: 'First name is required',
    parent_name: 'Last name is required',


}
export default staffValidation;