import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './MemberSummary.css';
import memberHTTPService from '../../../main/services/memberHTTPService';
import { NavLink, useHistory } from 'react-router-dom';
const MemberSummary = () => {
  const [all, setAll] = useState(0);
  const [today, setToday] = useState(0);
  let history = useHistory()

  useEffect(() => {
    memberHTTPService.getTotalMember().then(data => {
      setAll(data.data.all)
    })
    memberHTTPService.getTodayMember().then(data => {
      setToday(data.data.today)
    })

  }, []);

  return (
    <div className="MemberSummary row">

       <div className="col-lg-3 col-md-6 col-sm-6" style={{cursor: 'pointer'}} onClick={()=>history.push("/card-register")} >
        <div className="card card-stats" style={{background:"#60be44"}}>
          <div className="card-body ">
            <div className="row">
              <div className="col-5 col-md-4">
                <div className="icon-big text-center icon-warning">
                  <i className="nc-icon nc-badge white"></i>
                </div>
              </div>
              <div className="col-7 col-md-8">
                <div className="numbers">
                  <p className="card-category " onClick={()=>history.push("/card-register")} style={{color:"white",fontWeight:"bold",cursor: 'pointer'}}>Register New</p>
                  <p className="card-category " onClick={()=>history.push("/card-register")} style={{color:"white",fontWeight:"bold", fontSize:'20px',cursor: 'pointer'}}>Card</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="col-lg-3 col-md-6 col-sm-6">
        <div className="card card-stats">
          <div className="card-body ">
            <div className="row">
              <div className="col-5 col-md-4">
                <div className="icon-big text-center icon-warning">
                  <i className="nc-icon nc-chart-bar-32 purple"></i>
                </div>
              </div>
              <div className="col-7 col-md-8">
                <div className="numbers">
                  <p className="card-category">Total</p>
                  <p className="card-title purple">{all}</p><p>
                  </p></div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="col-lg-3 col-md-6 col-sm-6">
        <div className="card card-stats">
          <div className="card-body ">
            <div className="row">
              <div className="col-5 col-md-4">
                <div className="icon-big text-center icon-warning">
                  <i className="nc-icon nc-chart-bar-32 blue"></i>
                </div>
              </div>
              <div className="col-7 col-md-8">
                <div className="numbers">
                  <p className="card-category">Today</p>
                  <p className="card-title blue">{today}</p><p>
                  </p></div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="col-lg-3 col-md-6 col-sm-6">
        <div className="card card-stats">
          <div className="card-body ">
            <div className="row">
              <div className="col-5 col-md-4">
                <div className="icon-big text-center icon-warning">
                  <i className="nc-icon nc-chart-bar-32 red"></i>
                </div>
              </div>
              <div className="col-7 col-md-8">
                <div className="numbers">
                  <p className="card-category">This week</p>
                  <p className="card-title red">0</p><p>
                  </p></div>
              </div>
            </div>
          </div>

        </div>
      </div>



    </div>
  )
};

MemberSummary.propTypes = {};

MemberSummary.defaultProps = {};

export default MemberSummary;
