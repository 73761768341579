import React, { useCallback, useContext, useEffect, useState } from 'react';
import Scanner from './Scanner';
import './Scanner.css'
import { ActionsContext } from '../../../../libraries/contexts/context';
import attendanceHTTPService from '../../../../main/services/attendanceHTTPService';
import memberHTTPService from '../../../../main/services/memberHTTPService';
import showMessage from '../../../../libraries/messages/messages';
import productMessage from '../../../../main/messages/productMessage';
import { HTTP_ERR_MESSAGE } from '../../../../main/messages/generic.message';
import logo from '../../../../logo.svg';
import Chip from '@mui/material/Chip';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import user_kids from '../../../../user_kids.png'
import {Howl, Howler} from 'howler';
import error from '../../../../main/music/error.mp3';
import success from '../../../../main/music/success.mp3';



export default function  NfcCardReader() {
  
  let yourDate = new Date().toISOString()
  let currentDate = yourDate.substring(0, yourDate.length, -2)

  const initialState = {
    member: "",
    date: currentDate,
    user_code:''
  };

  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [memberName, setMemberName] = useState('');
  const { actions, setActions} = useContext(ActionsContext);
  const [createAttendance, setCreateAttendance] = useState(initialState)
  const { register, handleSubmit, errors } = useForm();
  const [members, setMembers] = useState([]);
  const history = useHistory()

  
  var successSound = new Howl({
    src: [success],
    html5: true,
  });

  var errorSound = new Howl({
    src: [error],
    html5: true,
  });

  const getAllMember = () => {
    memberHTTPService.getAllMember()
      .then(response => {
        setMembers(response.data);
      })
      .catch(e => {
        showMessage('Error', HTTP_ERR_MESSAGE, 'warning')
      });
  };

const scan = useCallback(async() => {

  if ('NDEFReader' in window) { 
      try {
          const ndef = new window.NDEFReader();
          await ndef.scan();
          
          console.log("Scan started successfully.");
          ndef.onreadingerror = () => {
              console.log("Cannot read data from the this card. Try another one?");
          };
          
          ndef.onreading = event => {
              console.log("NDEF card read.");
              onReading(event);
              setActions({
                  scan: 'scanned',
                  write: null
              });
          };

      } catch(error){
          console.log(`Error! Scan failed to start: ${error}.`);
      };
  }
},[setActions]);




const CheckAttendance = async (data) => {
  try {
    const response = await attendanceHTTPService.checkAttendances(data);
    setMessage('Attendance Created');
    setMemberName(response.data.title );
    successSound.play();
  } catch (error) {
    const errorMes = error.response.data.message ? error.response.data.message : HTTP_ERR_MESSAGE;
    setErrorMessage(errorMes);
    setMemberName(error.response.data.member?error.response.data.member.member_name:'' );
    errorSound.play();

  }
};


const onReading = ({message, serialNumber}) => {
  setSerialNumber(serialNumber);
  CheckAttendance({cardNo:serialNumber,date:currentDate})
  // for (const record of message.records) {
  //     switch (record.recordType) {
  //         case "text":
  //             const textDecoder = new TextDecoder(record.encoding);
  //             setMessage(textDecoder.decode(record.data));
  //             break;
  //         case "url":
  //             // TODO: Read URL record with record data.
  //             break;
  //         default:
  //             // TODO: Handle other records with record data.
  //         }
  // }
};

useEffect(() => {
  scan();
}, [scan]);

useEffect(() => {
  getAllMember()
}, []);

useEffect(() => {
  const timer = setTimeout(() => {
    setActions({scan: 'scanning',write: null});
    setErrorMessage('');
    setMessage('');
    setMemberName('');
  }, 5000);

  return () => clearTimeout(timer);
}, [actions.scan]); 


const onSubmit = async (data) => {
  try {
    const response = await attendanceHTTPService.createtendances(data);
    setMessage('Attendance Created');
    console.log(response);
    setMemberName(response.data.title );
    setActions({
      scan: 'scanned',
      write: null
  });
  successSound.play();
  } catch (error) {
    const errorMes = error.response.data.message ? error.response.data.message : HTTP_ERR_MESSAGE;
    setErrorMessage(errorMes);
    setMemberName(error.response.data.member?error.response.data.member.member_name:'' );
    setActions({
      scan: 'scanned',
      write: null
  });
  errorSound.play();
  }
}

const handleInputChange = event => {
  if(event!==null){
    setCreateAttendance({ ...createAttendance, ['member']: event.value, user_code:event.data_user_code });
  }
};

const options = members.map(item => ({
  label: `${item.member_name}`,
  value: item.member_name,
  'data_user_code': item.user_code
}));



  return (
    <div className="scanner" >
    <img src={logo}  className='main-logo' alt="finwin" />

    {actions.scan === 'scanned' ?  
    <div style={{ background:errorMessage ?'#ff3c00':message?'#04b542':'#fff', padding:'100px', borderRadius:'20px'}}>
      <div class="our-team">
        <div class="picture">
          <img class="img-fluid" style={{width:'20%'}} src={user_kids}/>
        </div>
        <div class="team-content">
          <h3 class="name text-white">{memberName}</h3>
        </div>
      </div>


        {message &&
        <>
          <svg  width="100" height="100" viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
		   	</svg>
           <Chip   sx={{
          height: 'auto',
          '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal',
          },
        }} label={message} style={{fontWeight:'bold',fontSize:'20px', marginTop:'10px'}} color="success" />
        </>
       }


        {errorMessage&& 
         <div >
               <svg width="100" height="100" viewBox="0 0 354 350" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="warning" clip-path="url(#clip0)" filter="url(#filter0_d)">
          <path id="Vector" d="M23.1782 341.846C17.8761 339.739 12.9242 337.707 9.04747 332.727C2.36302 324.164 2.9344 315.623 7.2627 306.912C18.8775 283.523 30.8303 260.303 42.6694 237.025C65.5468 192.034 88.456 147.056 111.397 102.091C112.365 100.197 114.076 97.7929 115.83 97.4214C117.817 96.9824 120.997 97.9127 122.262 99.4171C123.38 100.746 123.251 103.955 122.49 105.865C120.677 110.39 118.155 114.63 115.937 118.996C104.813 140.876 93.6895 162.76 82.5672 184.647C70.1598 208.957 57.6357 233.205 45.2559 257.531C35.9849 275.748 26.8 294 17.701 312.288C12.6047 322.555 19.5103 329.046 28.468 330.136C29.6968 330.283 30.9593 330.163 32.2065 330.163C129.444 330.163 226.682 330.137 323.92 330.083C327.179 330.083 330.7 329.433 333.633 328.079C337.968 326.083 340.063 320.345 337.934 315.764C331.585 302.05 324.997 288.445 318.169 274.948C306.038 251.056 293.594 227.326 281.383 203.473C270.14 181.497 258.979 159.482 247.9 137.43C238.282 118.394 228.765 99.3127 219.122 80.2926C209.222 60.7721 199.273 41.2761 189.273 21.8047C188.584 17.313 183.694 11.2124 179.676 11.1418C176.911 11.0927 174.147 12.8519 171.36 13.8037C169.21 16.7296 166.633 19.4468 164.949 22.6276C160.034 31.9119 155.485 41.3867 150.751 50.7693C150.137 52.0158 149.123 53.0842 148.552 54.3522C146.745 58.365 143.455 58.8286 140.205 57.1154C136.679 55.2426 137.293 51.7364 138.669 48.6631C140.408 44.7731 142.395 40.9875 144.328 37.1927C148.19 29.5888 152.071 21.9941 155.97 14.4085C160.44 5.74742 167.004 0.125827 177.265 0.0152988C187.294 -0.0890891 194.16 5.13645 198.544 13.5673C211.556 38.6327 224.347 63.8208 237.142 88.9998C269.655 152.965 302.148 216.942 334.622 280.932C338.886 289.329 343.239 297.68 347.46 306.108C350.817 312.804 350.495 319.863 348.572 326.774C346.686 333.565 339.378 340.535 331.892 340.792H326.506C325.441 340.746 324.378 340.657 323.312 340.657C236.819 340.692 150.328 340.732 63.8378 340.777H28.6093L23.1782 341.846Z" fill="#F9F805" />
          <g id="Group7">
            <path id="Vector_2" d="M150.105 110.24C150.803 105.456 150.766 99.236 152.707 93.7065C156.507 82.8839 167.624 76.5654 179.857 77.0321C189.844 77.4128 201.074 85.512 203.431 95.7236C204.966 102.331 204.481 109.491 204.263 116.383C204.079 122.128 202.709 127.823 202.309 133.576C201.105 150.975 200.159 168.396 199.025 185.801C198.028 201.095 196.951 216.382 195.794 231.661C195.308 237.931 195.038 244.314 193.573 250.39C191.849 257.519 182.41 263.014 174.794 261.66C166.939 260.263 159.509 252.143 159.204 245.244C158.409 227.249 157.174 209.273 156.031 191.294C155.034 175.613 153.972 159.937 152.846 144.267C152.056 133.346 151.095 122.441 150.105 110.24ZM192.915 113.003L192.467 112.972C192.467 109.414 192.581 105.849 192.442 102.297C192.224 96.6907 189.567 92.6043 184.225 90.6547C172.736 86.4546 160.857 93.381 161.622 105.659C162.67 122.545 163.68 139.431 164.774 156.296C165.425 166.305 166.245 176.305 166.924 186.314C168.016 202.484 169.069 218.654 170.085 234.823C170.242 237.307 169.778 239.874 170.248 242.281C171.05 246.447 173.32 249.211 178.164 249.106C183.11 248.999 183.856 246.036 183.949 242.008C184.025 238.677 184.756 235.367 184.947 232.033C185.733 218.266 186.412 204.496 187.149 190.726C187.5 184.198 187.782 177.662 188.286 171.144C189.773 151.743 191.361 132.367 192.915 113.003Z" fill="#FAFA02" />
            <path id="Vector_3" d="M177.556 318.401C165.839 320.044 153.871 307.183 154.403 295.78C155.017 282.725 164.485 273.174 177.267 272.508C189.631 271.866 200.77 283.56 200.822 295.534C200.868 306.292 189.072 320.243 177.556 318.401ZM177.682 306.618C183.362 306.652 189.214 301.245 189.048 296.102C188.842 289.378 183.46 284.079 176.944 284.187C171.415 284.279 165.886 289.581 165.843 294.834C165.784 301.177 171.2 306.569 177.682 306.609V306.618Z" fill="#F9F804" />
          </g>
        </g>
        <defs>
          <filter id="filter0_d" x="0" y="0" width="354" height="351" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <clipPath id="clip0">
            <rect width="346" height="343" fill="white" transform="translate(4)" />
          </clipPath>
        </defs>
      </svg>

              <Chip   sx={{
          height: 'auto',
          '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal',
          },
        }} label={errorMessage} style={{fontWeight:'bold',fontSize:'20px', marginTop:'10px'}} color="warning" />
        </div>
        }


    </div>
    : 
    < >
     <Scanner status={actions.scan}></Scanner> 
     <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div class="form-group row">
                  <div><label for="text" class="col-12 col-form-label">Select Member</label></div>
                  <div class="col-12 ">
                  
                  <Select onChange={handleInputChange}  
                  value={createAttendance.member_name} 
                  options={options}  
                  isClearable={true}/>

                  </div>
                    <input value={createAttendance.date} ref={register({ required: true })}
                    id="hidden" name="date" type="hidden" class="form-control" />
                      <input value={createAttendance.user_code} ref={register({ required: true })}
                    id="hidden" name="user_code" type="hidden" class="form-control" />
                </div>
                <div class="form-group row">
                  <div class="offset-md-2 col-12">
                    <button onClick={()=> window.location.reload()}  class="btn btn-dark mr-3"> Go Back</button>
                    <button name="submit" type="submit" class="btn btn-success"> Attend</button>
                     {/* <button  onClick={()=>{CheckAttendance({cardNo:'04:4a:d8:62:10:02:89',date:currentDate});    setActions({
      scan: 'scanned',
      write: null
  }); successSound.play();}} class="btn btn-success" > test</button>  */}
                  </div>
                </div>
              </form>
            </div>

    </ >
   
    }
  </div>
  )
}
