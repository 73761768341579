import React, { useEffect, useState } from 'react';
import './DashBoard.css';
import { Bar, Pie } from 'react-chartjs-2';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css'; // a dependency of timegrid
import '@fullcalendar/timegrid/main.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement
} from 'chart.js';

import { chartBarOption, intialChartBarData } from '../../../main/config/chart.bar';
import expenseHTTPService from '../../../main/services/expenseHTTPService';
import showMessage from '../../../libraries/messages/messages';
import activityHTTPService from '../../../main/services/activityHTTPService';
import revenueHTTPService from '../../../main/services/revenueHTTPService';
import memberHTTPService from '../../../main/services/memberHTTPService';
import attendanceHTTPService from '../../../main/services/attendanceHTTPService';
import activityHTTPServiceCopy from '../../../main/services/activityHTTPService copy';
import staffHTTPService from '../../../main/services/staffHTTPService';
import groupHTTPService from '../../../main/services/groupHTTPService';
import settingsHTTPService from '../../../main/services/settingsHTTPService';
import { useHistory } from 'react-router-dom';
import typeSubsHTTPService from '../../../main/services/typeSubsHTTPService';
import { HTTP_ERR_MESSAGE } from '../../../main/messages/generic.message';
import NfcCardReader from '../NfcCardRW/NfcCardReader/NfcCardReader';
import { ActionsContext } from '../../../libraries/contexts/context';
import CardRegister from '../../memberModule/CardRegister/CardRegister';
import ManualAttendance from '../../memberModule/ManualAttendance/ManualAttendance';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend, ArcElement, BarElement
);

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
export const data3 = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [3, 5, 6, 4, 2, 7, 8],
      borderColor: '#ffa400',
      backgroundColor: '#ffa400',
    }
  ],
};
const labels3 = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};
export const data2 = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [1, 3, 4, 5, 11, 3, 2],
      borderColor: '#ffa400',
      backgroundColor: '#ffa400',
    }

  ],
};

export const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};
const DashBoard = () => {


  const [expenseChart, setExpenseChart] = useState(intialChartBarData);
  const [incomeChart, setIncomeChart] = useState(data2);
  const [memberLine, setmemberLine] = useState(data2);
  const [activityPie, setActivityPie] = useState(data);
  const [attendance, setAttendance] = useState([]);
  const [cardDetails, setCardDetails] = useState([]);
  const [groupCount, setGroupCount] = useState(0);
  const [memberCount, setMemberCount] = useState(0);
  const [staffCount, setStaffCount] = useState(0);
  const [activityCount, setActivityCount] = useState(0);
  const [dashboardSettings, setDashboardSettings] = useState([]);
  let history = useHistory()
  const [attendanceCheckClick, setAttendanceCheckClick] = useState(false);
  const [registerCheckClick,setRegisterCheckClick] = useState(false);
  const [manualAttendanceClick,setManualAttendanceClick] = useState(false);

  const [actions, setActions] = useState(null);
  const {scan, write} = actions || {};

  const actionsValue = {actions,setActions};

  const onHandleAction = (actions) =>
  {
    setActions({...actions});
  }


  useEffect(() => {
    // if (localStorage.getItem('connected') == undefined) {
    //   history.push("/login")
    // }
    
    getExpenseChartData()
    getIncomeChartData()
    getMemberLinetData()
    getActivityPieData()
    getAttendencesCalendar()
    getActivityCount()
    getMemberCount()
    getStaffCount()
    getGroupCount()
    getDashboardSettings()



  }, []);

  const getActivityCount = () => {
    activityHTTPService.getCountActivity().then(data => {
      setActivityCount(data.data.activity)
    })
  }

  const getMemberCount = () => {
    memberHTTPService.getCountMember().then(data => {
      setMemberCount(data.data.member)
    })
  }

  const getStaffCount = () => {
    staffHTTPService.getCountStaff().then(data => {
      setStaffCount(data.data.staff)
    })
  }

  const getGroupCount = () => {
    groupHTTPService.getCountGroup().then(data => {
      setGroupCount(data.data.group)
    })
  }

  const getAttendencesCalendar = () => {
    attendanceHTTPService.getAllAtendances()
      .then(response => {
        setAttendance(response.data);
      })
      .catch(e => {
        showMessage('Error', HTTP_ERR_MESSAGE, 'warning')
      });
  };

  const getCardDetails = () => {
    attendanceHTTPService.getCardDetails()
      .then(response => {
        setCardDetails(response.data);
      })
      .catch(e => {
        showMessage('Error', HTTP_ERR_MESSAGE, 'warning')
      });
  };


  const getExpenseChartData = () => {

    expenseHTTPService.getExpenseByDate()
      .then(response => {
        setExpenseChart(response.data);
      })
      .catch(e => {
        showMessage('Error', HTTP_ERR_MESSAGE, 'warning')
      });
  };

  const getMemberLinetData = () => {

    memberHTTPService.getAllMemberByDate()
      .then(response => {
        setmemberLine(response.data);
      })
      .catch(e => {
        showMessage('Error', HTTP_ERR_MESSAGE, 'warning')
      });
  };

  const getIncomeChartData = () => {

    revenueHTTPService.getAllRevenueByDate()
      .then(response => {
        setIncomeChart(response.data);
      })
      .catch(e => {
        showMessage('Error', HTTP_ERR_MESSAGE, 'warning')
      });
  };

  const getActivityPieData = () => {
    activityHTTPService.getAllActivityByDate()
      .then(response => {
        setActivityPie(response.data);
      })
      .catch(e => {
        showMessage('Error', HTTP_ERR_MESSAGE, 'warning')
      });
  };

  const getDashboardSettings = () => {
    settingsHTTPService.getDashboardSettings().then(data => {
      setDashboardSettings(data.data[0])

    })
  }

  return (
    <div classNameName="content">
      <div classNameName="row">
        <div classNameName="col-md-12">
          <div classNameName="card">
            <div classNameName="card-header">
              <h4 classNameName="card-title">Finwin Sports</h4>
            </div>
            <div classNameName="card-body">
              <div className="row">

             {!attendanceCheckClick&& <div onClick={()=>{onHandleAction({scan: 'scanning', write: null});setAttendanceCheckClick(true);setRegisterCheckClick(false)}} className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-body ">
                        <div className="row">
                          <div className="col-5 col-md-4">
                            <div className="icon-big text-center icon-warning">
                              <i className="nc-icon nc-tap-01 text-primary"></i>
                            </div>
                          </div>
                          <div className="col-7 col-md-8">
                            <div className="numbers">
                              <p className="card-category">Check Attendance</p>
                              </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>} 

                  {actions && attendanceCheckClick && 
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <ActionsContext.Provider value={actionsValue}>
                       <NfcCardReader/>
                       </ActionsContext.Provider>  
                      </div>
                    </div>
                  </div>
                }
                 {registerCheckClick &&
                  <div className="col-md-6 ">
                    <div className="card">
                      <div className="card-body">
                       <CardRegister/> 
                      </div>
                    </div>
                  </div>
                }

{!registerCheckClick&&   <div onClick={()=>{onHandleAction({scan: null, write: null});setAttendanceCheckClick(false);setRegisterCheckClick(true)}} className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-body ">
                        <div className="row">
                          <div className="col-5 col-md-4">
                            <div className="icon-big text-center icon-warning">
                              <i className="nc-icon nc-credit-card text-warning"></i>
                            </div>
                          </div>
                          <div className="col-7 col-md-8">
                            <div className="numbers">
                              <p className="card-category">Register New Card
                              </p></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}

                 <div onClick={()=>history.replace('/payment')} className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-body ">
                        <div className="row">
                          <div className="col-5 col-md-4">
                            <div className="icon-big text-center icon-warning">
                              <i className="nc-icon nc-money-coins text-warning"></i>
                            </div>
                          </div>
                          <div className="col-7 col-md-8">
                            <div className="numbers">
                              <p className="card-category">Payment
                              </p></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div onClick={()=>setManualAttendanceClick(true)} className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-body ">
                        <div className="row">
                          <div className="col-5 col-md-4">
                            <div className="icon-big text-center icon-warning">
                              <i className="nc-icon nc-zoom-split text-warning"></i>
                            </div>
                          </div>
                          <div className="col-7 col-md-8">
                            <div className="numbers">
                              <p className="card-category">Manual Attendance
                              </p></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {manualAttendanceClick &&
                  <div className="col-md-6 ">
                    <div className="card">
                      <div className="card-body">
                       <ManualAttendance setManualAttendanceClick={setManualAttendanceClick}/> 
                      </div>
                    </div>
                  </div>
                }

             

              </div>
            </div>
          </div>
        </div>

      </div>
    </div >
  )
};

DashBoard.propTypes = {};

DashBoard.defaultProps = {};

export default DashBoard;
