import React, { useContext } from 'react';
import './Scanner.css'
import Spinner from '../../../../spinner.gif';
import { ActionsContext } from '../../../../libraries/contexts/context';

const Scanner = () => {
  const { actions, setActions} = useContext(ActionsContext);
    return (
      <div>
        <div className="scanner-container">
          <img src={Spinner} alt="spinning log" className="scanner-image"/>
          <p className="scanner-text">
            Touch your card
          </p>
        </div>
      </div>
    );
};

export default Scanner;