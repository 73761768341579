import React, { useEffect, useState } from 'react';
import './Content.css';
import { BrowserRouter as Router, Route } from "react-router-dom"
import Navigation from './../../shared/Navigation/Navigation';
import Header from './../../shared/Header/Header';
import Footer from './../../shared/Footer/Footer';
import Login from '../Login/Login';
import NfcCardReader from '../../../modules/shared/NfcCardRW/NfcCardReader/NfcCardReader';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Logo from "../../../logo.svg";
import { ActionsContext } from '../../../libraries/contexts/context';

import Routes from '../Routes/Routes';
const Content = () => {

  const [connected, setConnected] = useState(false);
  const [isloginClick,setIsloginClick]= useState(false);
  const [isAttendanceClick,setIsAttendanceClick]= useState(false);
  const [actions, setActions] = useState(null);
  const {scan, write} = actions || {};

  const actionsValue = {actions,setActions};

  const onHandleAction = (actions) =>{
    setActions({...actions});
  }

  useEffect(() => {
    const isLogin = localStorage.getItem('connected');
    if (isLogin === 'true') {
      setConnected(true);
    }
  }, []);

  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://www.finwincityacademy.com">
        Finwin City Sports Academy
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  
  const handleClick = num => {
   setConnected(num)
  //  localStorage.setItem('connected', num);
  };

  return (

    <Router>
      {connected === true ?

        <div>
          <Navigation connected={connected} />
          <div className="main-panel">
            <Header connected={connected} handleClick={handleClick} />
            <Routes connected={connected} />
            <Footer connected={connected} />
          </div></div> 
      : connected === false && isloginClick === true ?
        <Login handleClick={handleClick} />
      : connected === false && isAttendanceClick === true ?
      <ActionsContext.Provider value={actionsValue}>
      <NfcCardReader /> 
      </ActionsContext.Provider>
      :
      <Container component="main" maxWidth="xs">
       <CssBaseline />
       <Box
         sx={{
           marginTop: 8,
           display: 'flex',
           flexDirection: 'column',
           alignItems: 'center',
         }}
       >
       <img src={Logo}/>

         <Box component="form" noValidate sx={{ mt: 1 }}>
         <Container  maxWidth="xs">
     <CssBaseline /> 
     
     <section className="buttons">
     <button className="buttons__popup accepting" onClick={()=>{setIsAttendanceClick(true);onHandleAction({scan: 'scanning', write: null})}}>
       Attendance
     </button>
     <button className="buttons__popup canceling" onClick={()=>setIsloginClick(true)}>
      Admin Login
     </button>
   </section>
     </Container>
         </Box>
       </Box>
       <Copyright sx={{ mt: 8, mb: 4 }} />
     </Container>
          } 
    </Router>

  )


};

Content.propTypes = {};

Content.defaultProps = {};

export default Content;
