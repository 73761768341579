
import React, { useState } from 'react';
import './AddGroup.css';
import { useForm } from 'react-hook-form';
import showMessage from '../../../libraries/messages/messages'
import groupMessage from '../../../main/messages/groupMessage'
import groupValidation from '../../../main/validations/groupValidation'
import groupHTTPService from '../../../main/services/groupHTTPService';
import ReactTooltip from 'react-tooltip';
import { HTTP_ERR_MESSAGE } from '../../../main/messages/generic.message';

const AddGroup = (props) => {

  const initialState = {
    group_name: "",

  };

  const { register, handleSubmit, errors } = useForm()
  const [group, setGroup] = useState(initialState);

  const onSubmit = (data) => {
    groupHTTPService.createGroup(data).then(data => {
      setGroup(initialState)
      showMessage('Confirmation', groupMessage.add, 'success')
      props.closeModal(data)
    }).catch(e => {
      showMessage('Error', HTTP_ERR_MESSAGE, 'warning')
    })

  }

  const handleInputChange = event => {
    const { name, value } = event.target;
    setGroup({ ...group, [name]: value });
  };


  return (
    <div className="AddGroup">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ReactTooltip />
        <div class="form-group row">
          <label for="text" class="col-4 col-form-label">Group Name <i data-tip="Group Name" class="fa fa-question-circle" aria-hidden="true"></i> </label>
          <div class="col-8">
            <input onChange={handleInputChange} value={group.group_name} ref={register({ required: true })}
              id="text" name="group_name" type="text" class="form-control" />
            <div className="error text-danger">
              {errors.group_name && groupValidation.group_name}
            </div>
          </div>
        </div>


        <div class="form-group row">
          <div class="offset-4 col-8">
            <button name="submit" type="submit" class="btn btn-primary"><i class="far fa-save"></i>Save </button>
          </div>
        </div>

      </form>
    </div>
  )
};

AddGroup.propTypes = {};

AddGroup.defaultProps = {};

export default AddGroup;
